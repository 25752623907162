import React, { forwardRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { DataTable as PrimeDataTable } from 'primereact/datatable'
import ObjectUtils from 'primereact/components/utils/ObjectUtils'
import styled from 'styled-components'
import _ from 'lodash'

import colors from '../colors.scss'
import { Panel, ToolPanel } from './panel'
import { TabView } from './tabView'
import { shadeColor } from 'Utils/color'
import { SearchInput } from 'Components/input'
import { format, parse } from 'date-fns'
import { RichTextDisplay } from './richTextDisplay'

export { Column } from 'primereact/column'

const darkerBgOdd = shadeColor(colors.darkerBackgroundColor, 20)
const darkerLightBgOdd = shadeColor(colors.white, -3)

const DataTableDefaults = ({ children, ...props }, ref) => {
  const intl = useIntl()
  return (
    <PrimeDataTable ref={ref} responsive={true} emptyMessage={intl.formatMessage({ id: 'common.no_results' })}
                    csvSeparator=";" {...props}>
      {children}
    </PrimeDataTable>
  )
}

export const DataTableWithSearch = ({ children, tools, ...props }) => {
  const [globalFilter, setGlobalFilter] = useState('')
  const [first, setFirst] = useState(0)
  return (
    <>
      <ToolPanel>
        <SearchInput
          value={globalFilter}
          onChange={(event) => {
            setGlobalFilter(event.target.value)
            setFirst(0)
          }}
        />
        {tools}
      </ToolPanel>
      <DataTable {...props} globalFilter={globalFilter} first={first} onPage={(e) => setFirst(e.first)}>
        {children}
      </DataTable>
    </>
  )
}

export const DataTable = styled(forwardRef(DataTableDefaults))`
  &&& {
    &.p-datatable {
      .p-row-editor-init-icon,
      .p-row-editor-save-icon,
      .p-row-editor-cancel-icon {
        color: ${colors.primary};
        font-size: 30px;
      }

      .p-datatable-wrapper {
        min-height: 100px;
      }

      .p-datatable-emptymessage td {
        height: 61px;
        text-align: center;
      }

      > ${Panel} & {
        margin: 0 -20px;
      }

      ${TabView} & {
        margin: 0;
      }

      &.clickable-rows td {
        cursor: pointer;
      }

      .p-datatable-tbody > tr.p-datatable-dragpoint-top > td {
        box-shadow: inset 0 2px 0 0 ${colors.primary};
      }

      .p-datatable-tbody > tr.p-datatable-dragpoint-bottom > td {
        box-shadow: inset 0 -2px 0 0 ${colors.primary};
      }

      tr,
      tr:nth-child(even) {
        background-color: ${(props) => (props.theme === 'light' ? colors.white : colors.darkerBackgroundColor)};
        color: ${(props) => (props.theme === 'light' ? colors.lightBlack : colors.slightLighterWhite)};

        &:hover {
          background-color: ${(props) =>
  props.theme === 'light' ? colors.lightestNotWhite : colors.darkerBackgroundHoverColor} !important;
        }

        > th {
          background-color: ${(props) => (props.theme === 'light' ? colors.darkerLightWhite : colors.darkBlue)};
          color: ${(props) => (props.theme === 'light' ? colors.lightBlack : colors.slightLighterWhite)};
          border: none;
          text-align: left;
          white-space: nowrap;

          &.p-sortable-column:not(.p-highlight):hover {
            /*filter: brightness(110%);*/
            background-color: ${(props) => (props.theme === 'light' ? colors.lightHover : colors.darkBlue)};
            color: ${(props) => (props.theme === 'light' ? colors.black : colors.text)};

            & .p-sortable-column-icon {
              color: ${(props) => (props.theme === 'light' ? colors.black : colors.text)};
            }
          }
        }

        & .p-sortable-column-icon {
          vertical-align: text-bottom;
          color: ${(props) => (props.theme === 'light' ? colors.midGrey : colors.text)};
        }

        &.disabled {
          background-color: ${(props) => (props.theme === 'light' ? colors.white : colors.darkerBackgroundColor)};

          &:hover {
            background-color: ${(props) => (props.theme === 'light' ? colors.white : colors.darkerBackgroundColor)} !important;
          }

          color: ${colors.disabledWhite};

          td {
            cursor: default;
          }
        }
      }

      /* order is important - this needs to be below the base tr declaration above or odd rows will not be disabled */

      &.disabled-rows {
        tr {
          background-color: ${(props) => (props.theme === 'light' ? colors.white : colors.darkerBackgroundColor)};

          &:hover {
            background-color: ${(props) => (props.theme === 'light' ? colors.white : colors.darkerBackgroundColor)} !important;
          }

          color: ${colors.disabledWhite};
        }

        tr:nth-child(odd) {
          background-color: ${(props) => (props.theme === 'light' ? colors.white : colors.darkerBackgroundColor)} !important;
        }
      }

      tr > td {
        border: none;
        border-bottom: 1px solid ${(props) => (props.theme === 'light' ? colors.lightBorder : colors.darkestBlueBorder)};

        overflow: hidden;
        text-overflow: ellipsis;
      }

      .p-paginator {
        .p-dropdown {
          height: auto;
        }

        .p-dropdown-panel .p-dropdown-items {
          .p-dropdown-item.p-highlight {
            background-color: ${colors.primary};
          }
        }

        .p-dropdown-label {
          background-color: ${colors.invert};

          &.p-inputtext {
            padding-top: 6px;
            padding-bottom: 6px;
          }
        }

        .p-dropdown-trigger {
          background-color: ${colors.invert};
        }
      }

      /* mobile */
      @media screen and (max-width: 640px) {
        .p-datatable-loading-content {
          top: calc(50% - 16px);
        }

        .p-datatable-wrapper {
          min-height: 50px;
        }

        .p-datatable-emptymessage td {
          height: 50px;
        }

        tr:nth-child(odd) {
          background-color: ${(props) => (props.theme === 'light' ? darkerLightBgOdd : darkerBgOdd)};
        }

        td {
          display: flex;
        }

        .p-column-title {
          vertical-align: top;
          width: 50%;
          padding: 0 10px 0 0;
          margin: 0;
          text-align: left;
        }
      }

      .p-paginator {
        background-color: ${(props) => (props.theme === 'light' ? colors.darkerLightWhite : colors.darkBlue)};
        border: none;

        .p-paginator-pages {
          .p-paginator-page {
            color: ${(props) => (props.theme === 'light' ? colors.lightBlack : colors.white)};

            &:hover {
              background-color: ${(props) => (props.theme === 'light' ? colors.lightHover : colors.lightBackground)};
              color: ${(props) => (props.theme === 'light' ? colors.black : colors.white)};
            }

            &.p-highlight {
              background-color: ${colors.primary};
              color: ${colors.white};
            }
          }
        }

        .p-paginator-first:not(.p-disabled):not(.p-highlight),
        .p-paginator-last:not(.p-disabled):not(.p-highlight),
        .p-paginator-next:not(.p-disabled):not(.p-highlight),
        .p-paginator-prev:not(.p-disabled):not(.p-highlight) {
          color: ${(props) => (props.theme === 'light' ? colors.lightBlack : colors.white)};

          &:hover {
            background-color: ${(props) => (props.theme === 'light' ? colors.lightHover : colors.lightBackground)};
            color: ${(props) => (props.theme === 'light' ? colors.black : colors.white)};
          }
        }
      }

      /* medium screens */
      @media screen and (min-width: 641px) and (max-width: 1600px) {
        .p-datatable-emptymessage td {
          height: 63px;
        }

        tr > td,
        tr > th {
          font-size: 13px;
        }
      }

      /* non-mobile */
      @media screen and (min-width: 641px) {
        tr > th,
        tr > td {
          padding: 10px;
        }
      }
    }
  }
`

export const dateBody = (row, column) => {
  const value = ObjectUtils.resolveFieldData(row, column.field)
  return value && <span>{format(parse(value), 'YYYY-MM-DD')}</span>
}

export const datetimeBody = (row, column) => {
  const value = ObjectUtils.resolveFieldData(row, column.field)
  return value && <span>{format(parse(value), 'YYYY-MM-DD HH:mm:ss')}</span>
}

export const commaSeparatedBody = (row, column) => {
  const value = ObjectUtils.resolveFieldData(row, column.field)
  return _.isArray(value) ? value.join(', ') : value
}

export const richTextBody = (row, column) => <RichTextDisplay value={ObjectUtils.resolveFieldData(row, column.field)}/>

export const checkCircleBody = (row, column) => {
  const value = ObjectUtils.resolveFieldData(row, column.field)
  const style = {
    fontSize: '30px',
    color: value === true || (_.isArray(value) && value.length > 0) ? '#979699' : '#41404A'
  }
  return <span style={style} className="pi pi-check-circle"/>
}

export const arrayCommaBody = (row, column) => {
  const value = ObjectUtils.resolveFieldData(row, column.field)
  return value && _.isArray(value) ? value.join(', ') : value
}

export const arrayCountBody = (row, column) => {
  const value = ObjectUtils.resolveFieldData(row, column.field)
  return value && _.isArray(value) ? value.length : 0
}
