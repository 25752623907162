import React from 'react'
import styled from 'styled-components'
import colors from '../colors.scss'
import { Chart } from 'primereact/chart'

import { Button } from '../readonly'
import { DataTable } from '../readonly/datatable'

export const RelativeAnchor = styled.div`
  position: relative;
`

export const SmallText = styled.div`
  font-size: 12px;
`

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;
  flex: 1 0 0px;
`

export const MainContainer = styled.div`
  display: flex;
  flex: 1 0 0px;
  align-items: stretch;
  overflow: hidden;
`

export const ContentContainer = styled.div`
  display: flex;
  flex: 1 1 0%;
  overflow-y: scroll; /* has to be scroll, not auto on iOS */
  -webkit-overflow-scrolling: touch;
  flex-direction: column;

  &,
  & * {
    /* width */

    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
    }

    /* Track */

    ::-webkit-scrollbar-track {
      background: #222;
    }

    /* Handle */

    ::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 20px;
    }

    /* Handle on hover */

    ::-webkit-scrollbar-thumb:hover {
      background: #999;
    }

    ::-webkit-scrollbar-corner {
      background: rgba(0, 0, 0, 0.5);
    }
  }

  @media screen and (min-width: 640px) {
    > * {
      padding: 25px;
    }
  }
`
export const PageConstraint = styled.div`
  max-width: 1900px;
`

export const PageHeader = styled.div`
  @media screen and (max-width: 640px) {
    padding: 25px 25px 0 25px;
  }
`

export const TextContainer = styled.div`
  max-width: 700px;
`

export const H3Line = styled.h3`
  border-bottom: 1px solid #6b6b6b;
  margin-bottom: 5px;
`

export const ButtonBar = styled.div`
  button {
    margin-right: 20px;
  }

  @media screen and (max-width: 640px) {
    display: flex;
    flex-direction: column;

    button {
      margin-right: 0;
    }
  }
`
export const Spacer = styled.div`
  flex: 1 1 0%;
`

export const Section = styled.div`
  padding-bottom: 10px;
`

export const LongerSection = styled(Section)`
  padding-bottom: 30px;
`

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
`

export const FlexRowResponsive = styled(FlexRow)`
  @media (max-width: 640px) {
    flex-direction: column;
  }
`

export const FlexRowResponsiveLg = styled(FlexRow)`
  @media (max-width: 1280px) {
    flex-direction: column;
  }
`

export const FlexRowPadded = styled(FlexRowResponsive)`
  & > div {
    padding-right: 10px;
    flex: 1 0 auto;
  }
`

export const FlexRowCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

export const FlexRowCenterResponsive = styled(FlexRowCenter)`
  @media (max-width: 640px) {
    flex-direction: column;
    padding-bottom: 10px;
  }
`

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 1px;
`

export const FlexColumnCenter = styled(FlexColumn)`
  align-items: center;
`

export const FlexRowCenterHorizontal = ({ children }) => (
  <FlexRow>
    <div style={{ margin: '0 auto' }}>{children}</div>
  </FlexRow>
)

export const LineHeading = styled.span`
  font-weight: bold;
`

export const FlexFill = styled.div`
  flex: 1 1 auto;
`

export const FilterTableContainer = styled.div`
  display: flex;

  .filter {
    padding-left: 30px;
    width: 350px;
  }

  .tabs {
    flex: 1 1 1%;
    width: 100%;
  }
`

export const TableContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media (max-width: 1279px) {
    & {
      width: 100%;
    }
  }

  @media (min-width: 1280px) {
    & {
      flex-grow: 0;
      flex-basis: 100%;
    }
  }

  @media (min-width: 1600px) {
    & {
      flex-grow: 0;
      flex-basis: 100%;
    }
  }
`

export const ThinHeaderDataTable = styled(DataTable)`
  &&& {
    &.p-datatable .p-column-title {
      width: 30%;
    }
  }
  .hide-archive .pi-eye {
    color: #9b9ba2 !important;
  }
`

export const SidebarHeader = styled.div`
  display: flex;
  flex: 1 1 auto;

  ${Button} {
    margin-left: 10px;
  }

  h2 {
    padding: 0;
  }

  @media screen and (max-width: 992px) {
    flex-wrap: wrap;

    > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      width: 100%;
      padding-top: 10px;
    }
  }
`

export const SidebarScrollingContainer = styled.div`
  padding: 20px;
  overflow-y: auto;

  @media screen and (max-width: 640px) {
    overflow-y: scroll;
  }
`

export const SidebarHeading = styled.h2`
  @media screen and (max-width: 1200px) {
    padding-left: 20px;
  }
`

export const FieldError = styled.div`
  position: absolute;
  left: -20px;
  height: 100%;
  border-left: 4px solid ${colors.validationFailedFieldMarker};
`

export const StyledChart = styled(Chart)`
  margin-right: 10px;

  @media screen and (max-width: 640px) {
    margin: 0;
  }
`

export const ChartContainer = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 640px) {
    flex-direction: column;
    margin-bottom: 20px;
  }
`

export const ChartLegend = styled.div`
  @media screen and (max-width: 640px) {
    margin: 20px 0 0 0;
  }
`
export const ChartLegendRow = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '16px')};
`
export const ChartLegendCircle = styled.div`
  display: inline-block;
  background-color: ${(props) => props.color};
  width: 1em;
  height: 1em;
  border-radius: 0.5em;
  margin-right: 10px;
  flex: 0 0 1em;
`

export const ChartPageContainer = styled(FlexRowResponsiveLg)`
  margin-bottom: 30px;
`

export const CenterInfoPanel = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  align-items: center;
  min-height: calc(var(--vh, 1vh) * 100);

  div {
    width: 400px;
    padding: 40px;
  }
`
