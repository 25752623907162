import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import colors from '../colors.scss'
import { FlexRow } from '../layout'
import { useIntl } from 'react-intl'
import { Button, PdfPreviewWindow } from 'Components/readonly'

const FileWrapper = styled.div`
  > input {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }

  label {
    display: inline-block;
    cursor: pointer;

    &.button {
      background-color: ${colors.primary};
      border-radius: 20px;
      padding: 10px 40px;

      &:hover:not(.p-disabled) {
        filter: brightness(110%);
      }
    }

    &.text-icon {
      color: ${colors.primary};

      .pi {
        color: ${colors.primary};
        vertical-align: middle;
        font-size: 28px;
      }

      > span {
        vertical-align: middle;
      }
    }
  }

  .file {
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;

    ${FlexRow} {
      align-items: center;
    }

    > span {
      display: inline-block;
      text-overflow: ellipsis;
      width: ${(props) => 'calc(100% - 40px' + (props.hasFileRowComponent ? ' - 200px)' : ')')};
      white-space: nowrap;
      overflow: hidden;
      vertical-align: text-bottom;
    }

    .pi-trash {
      color: ${colors.primary};
      vertical-align: text-bottom;
      font-size: 28px;
      cursor: pointer;
    }

    .p-dropdown {
      width: 120px;
      margin-right: 5px;
      max-width: 140px;
    }

    .p-calendar {
      width: 200px;
    }

    > .p-dropdown {
      margin-right: 10px;
    }
  }

  .removedFile {
    text-decoration: line-through;
  }
`

const Error = styled.div`
  color: ${colors.validationFailedFieldMarker};

  i {
    vertical-align: bottom;
    margin-right: 8px;
  }

  margin-bottom: 8px;
`

const PdfPreviewLink = styled(Button)`
  padding: 0;
  cursor: pointer;
`

export const FileUpload = ({
                             id,
                             value,
                             onChange,
                             multiple,
                             text,
                             type,
                             deletionfield,
                             disabled,
                             FileRowComponent,
                             accept,
                             intialExtraValue,
                             maxSize
                           }) => {
  const inputRef = useRef(null)
  const [inputKey, setInputKey] = useState(Date.now())
  const [errorMessage, setErrorMessage] = useState('')
  const [previewPdfFile, setPreviewPdfFile] = useState(null)
  const intl = useIntl()

  const handleOnChange = (e) => {
    let fs = [...value]
    let maxFileSize = maxSize || 16000
    Array.from(inputRef.current.files).forEach((inputFile) => {
      let found = false
      fs.forEach((f) => {
        if (f.file.name === inputFile.name) {
          found = true
          f.deleted = false
          setErrorMessage('')
        }
      })
      if (!found) {
        if (maxFileSize && inputFile.size / 1024 > maxFileSize) {
          setErrorMessage(
            intl.formatMessage(
              { id: 'common.file_too_large' },
              { filename: inputFile.name, filesize: Math.round(inputFile.size / 1024), maxsize: maxFileSize }
            )
          )
        } else {
          setErrorMessage('')
          fs.push({ file: inputFile, deleted: false, extra: intialExtraValue })
        }
      }
    })
    onChange({
      target: {
        value: fs
      }
    })
  }

  const remove = (index) => {
    if (!disabled) {
      let fs = [...value]
      fs[index].deleted = true
      onChange({
        target: {
          value: fs
        }
      })
      setInputKey(Date.now())
    }
  }

  const hasVisibleFiles =
    value &&
    value.filter((f) => {
      return f.deleted === false
    }).length > 0

  const onExtraChange = (index, event) => {
    let fs = [...value]
    fs[index].extra = event.target.value
    onChange({
      target: {
        value: fs
      }
    })
  }

  return (
    <FileWrapper hasFileRowComponent={FileRowComponent}>
      <PdfPreviewWindow file={previewPdfFile} onClose={() => setPreviewPdfFile(null)}/>
      <input
        key={inputKey}
        type="file"
        id={id}
        name={id}
        ref={inputRef}
        multiple={multiple}
        onChange={handleOnChange}
        disabled={disabled}
        accept={accept || 'application/pdf'}
      />
      {errorMessage && (
        <Error>
          <i className="pi pi-exclamation-triangle"/>
          {errorMessage}
        </Error>
      )}
      {value &&
        value.map((f, i) =>
          f.deleted === false ? (
            <div className="file" key={i}>
              <FlexRow>
                {f.file.name.toLowerCase().endsWith('.pdf') ? (
                  <PdfPreviewLink
                    link
                    label={f.file.name + ' (' + intl.formatMessage({ id: 'common.preview' }) + ')'}
                    onClick={() => setPreviewPdfFile(f.file)}
                  />
                ) : (
                  <span>{f.file.name}</span>
                )}
                <i className="pi pi-trash" onClick={() => remove(i)}/>
              </FlexRow>
              {FileRowComponent && <FileRowComponent value={f.extra} onChange={(event) => onExtraChange(i, event)}/>}
            </div>
          ) : (
            f.id && (
              <div className="removedFile" key={i}>
                <span>{f.file.name}</span>
                <input type="hidden" name={deletionfield} value={f.id}/>
              </div>
            )
          )
        )}
      {(!hasVisibleFiles || multiple) &&
        (type === 'button' ? (
          <label htmlFor={id} className="button">
            {text}
          </label>
        ) : (
          <label htmlFor={id} className="text-icon">
            <i className="pi pi-plus-circle"/>
            <span>{text}</span>
          </label>
        ))}
    </FileWrapper>
  )
}
