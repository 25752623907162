import { Button, SearchInput } from 'Components/input'
import { PageConstraint, PageHeader, Spacer } from 'Components/layout'
import { checkCircleBody, DataTable, Modal, ToolPanel } from 'Components/readonly'
import { format } from 'date-fns'
import useGetData from 'Hooks/useGetData'
import useModalController from 'Hooks/useModalController'
import { Column } from 'primereact/column'
import React, { useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import styled from 'styled-components'
import { globalSearch } from 'Utils/search'
import OrganisationDialog from './components/organisationDialog'

const ModuleDateHeading = styled.div`
  font-size: 12px;
`

export const OrganisationsPage = () => {
  const intl = useIntl()
  const [globalFilter, setGlobalFilter] = useState('')
  const [organisation, setOrganisation] = useState(null)
  const [isLoading, organisations, setOrganisations] = useGetData('/rapi/admin/organisations', [])
  const [modalController] = useModalController({ title: intl.formatMessage({ id: 'admin.new_organisation' }) })
  const [first, setFirst] = useState(0)

  const onAddOrgSuccess = (data, isUpdated) => {
    if (isUpdated) {
      const newOrganisations = [...organisations]
      const indexOfUpdatedOrganisation = newOrganisations.findIndex((organisation) => organisation.id === data.id)
      newOrganisations[indexOfUpdatedOrganisation] = data
      setOrganisations(newOrganisations)
    } else {
      const newOrganisations = [...organisations]
      newOrganisations.push(data)
      setOrganisations(newOrganisations)
    }
  }

  const downloadSdsData = () => {
    window.open('/rapi/admin/sds-excel')
  }

  const downloadChemicalsData = () => {
    window.open('/rapi/admin/chemicals-excel')
  }

  const downloadCustomerData = () => {
    window.open('/rapi/admin/customers-excel')
  }

  const downloadUserData = () => {
    window.open('/rapi/admin/users-excel')
  }

  const translateKind = (row) => {
    return row.kind && <FormattedMessage id={'organisation.kind_' + row.kind}/>
  }

  const organisationList = React.useMemo(() => {
    return globalSearch([...organisations], globalFilter, [
      'name',
      'tool3_id',
      'address.Org_No',
      'has_sds',
      'has_chemical_list',
      'address.Address1',
      'address.Zip',
      'address.Town',
      'organisationMarkets'
    ])
  }, [globalFilter, organisations])

  const moduleBody = (row, column) => {
    const module = column.field.substring(4)
    const startDate = row[module + '_start_date']
    const endDate = row[module + '_end_date']

    return (
      <>
        {checkCircleBody(row, column)}
        {startDate && (
          <div>
            <ModuleDateHeading>
              <FormattedMessage id="common.start_date"/>
            </ModuleDateHeading>
            {format(startDate, 'YYYY-MM-DD')}
          </div>
        )}
        {endDate && (
          <div>
            <ModuleDateHeading>
              <FormattedMessage id="common.end_date"/>
            </ModuleDateHeading>
            {format(endDate, 'YYYY-MM-DD')}
          </div>
        )}
      </>
    )
  }

  const marketBody = (row, column) => {
    let markets = []
    if (row.organisationMarkets) {
      for (let i = 0; i < row.organisationMarkets.length; i++) {
        markets.push(intl.formatMessage({ id: 'lang.' + row.organisationMarkets[i] }))
      }
    }
    return <div>{markets.sort().reverse().join(', ')}</div>
  }

  return (
    <PageConstraint>
      <Modal
        modalController={modalController}
        ContentComponent={OrganisationDialog}
        onSuccess={onAddOrgSuccess}
        organisation={organisation}
      />
      <PageHeader>
        <h1>
          <FormattedMessage id="admin.customers"/>
        </h1>
        <ToolPanel>
          <SearchInput
            onChange={(event) => {
              setGlobalFilter(event.target.value)
              setFirst(0)
            }}
          />
          <Button
            primary
            label={intl.formatMessage({ id: 'admin.new_organisation' })}
            icon="pi pi-plus"
            onClick={() => {
              setOrganisation(null)
              modalController.open()
            }}
          />
          <Spacer/>
          <Button label={intl.formatMessage({ id: 'sds.download_sds_data' })} icon="pi pi-download"
                  onClick={downloadSdsData}/>
          <Button label={intl.formatMessage({ id: 'sds.download_chemicals_data' })} icon="pi pi-download"
                  onClick={downloadChemicalsData}/>
          <Button label={intl.formatMessage({ id: 'sds.download_customer_data' })} icon="pi pi-download"
                  onClick={downloadCustomerData}/>
          <Button label={intl.formatMessage({ id: 'sds.download_user_data' })} icon="pi pi-download"
                  onClick={downloadUserData}/>
        </ToolPanel>
      </PageHeader>
      <DataTable
        className="flextable clickable-rows"
        value={organisationList}
        paginator={true}
        rows={10}
        first={first}
        onPage={(e) => setFirst(e.first)}
        loading={isLoading}
        onRowClick={(row) => {
          setOrganisation(organisations.find((organisation) => organisation.id === row.data.id))
          modalController.open({ title: intl.formatMessage({ id: 'admin.edit-organisation' }) })
        }}>
        <Column field="name" header={intl.formatMessage({ id: 'common.name' })} sortable={true}/>
        <Column field="tool3_id" header="ID" style={{ wordBreak: 'break-all' }} sortable={true}/>
        <Column field="address.Org_No" header="Organisationsnummer" style={{ wordBreak: 'break-all' }} sortable={true}/>
        <Column
          field="has_sds"
          body={moduleBody}
          header="SDS M"
          style={{ wordBreak: 'break-all', width: '100px', textAlign: 'center' }}
          sortable={true}
        />
        <Column
          field="has_chemical_list"
          body={moduleBody}
          header="Chemical M"
          style={{ wordBreak: 'break-all', width: '100px', textAlign: 'center' }}
          sortable={true}
        />
        <Column field="address.Address1" header={intl.formatMessage({ id: 'common.address' })} sortable={true}/>
        <Column field="address.Zip" header={intl.formatMessage({ id: 'common.zip' })} sortable={true}/>
        <Column field="address.Town" header={intl.formatMessage({ id: 'common.town' })} sortable={true}/>
        <Column field="organisationMarkets" body={marketBody} header={intl.formatMessage({ id: 'common.markets' })}
                sortable={true}/>
        <Column
          field="weblink_key"
          header={intl.formatMessage({ id: 'admin.weblink2' })}
          style={{ wordBreak: 'break-all', width: '1%' }}
          sortable={true}
        />
        <Column field="kind" header={intl.formatMessage({ id: 'organisation.kind' })} body={translateKind}
                sortable={true}/>
      </DataTable>
    </PageConstraint>
  )
}
