import React from 'react'
import styled from 'styled-components'
import { ProgressSpinner } from 'primereact/progressspinner'
import axios from 'axios'
import { withRouter } from 'react-router-dom'
import _ from 'lodash'
import { useIntl } from 'react-intl'

import LocationsMap from './locationsMap'
import { AuthContext, GrowlContext } from 'Context'
import useGetData from 'Hooks/useGetData'
import { InventoryLockMessage } from 'Pages/inventory/components/inventoryLockMessage'
import { Modal } from 'Components/readonly'
import useModalController from 'Hooks/useModalController'
import modalContentConfirm from 'Components/combined/modalContentComponents/modalContentConfirm'

const StyledSpinner = styled(ProgressSpinner)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const removeLocationIdRecursive = (locations, tempId) => {
  return locations.filter((location) => {
    if (location.children) {
      location.children = removeLocationIdRecursive(location.children, tempId)
    }
    return location.id !== tempId
  })
}

const LocationsPage = ({ history }) => {
  const intl = useIntl()
  const { organisation } = React.useContext(AuthContext)
  const [locationsLoading, locations, setLocations] = useGetData('/rapi/locations', [], [organisation])
  const { displayError, displaySuccess } = React.useContext(GrowlContext)
  const [deleteLocationModalController] = useModalController({ title: intl.formatMessage({ id: 'common.delete' }) })

  const addLocation = (parent) => {
    // The location will not be saved unless it gets a name
    const tempId = 'temp_' + Date.now()
    const newLocation = {
      id: tempId,
      name: '',
      temporary: true,
      x: parent ? parent.x : 500,
      y: parent ? parent.y : 500,
      count: 0
    }
    if (parent) {
      if (!parent.children) {
        parent.children = []
      }
      parent.children.push(newLocation)
    } else {
      locations.push(newLocation)
    }
    setLocations([...locations])
    return newLocation
  }

  const checkRemoveLocation = (parent, location) => {
    const countLocations = (l) => {
      if (l.children) {
        let childrenCount = 0
        l.children.forEach((child) => {
          childrenCount += countLocations(child)
        })
        return 1 + childrenCount
      }
      return 1
    }

    const childrenCount = countLocations(location) - 1
    if (childrenCount > 0) {
      deleteLocationModalController.open({ data: { parent, location, count: childrenCount } })
    } else {
      removeLocation({ parent, location })
    }
  }

  const removeLocation = async (data) => {
    const parent = data.parent
    const location = data.location

    if (!location.temporary) {
      try {
        const result = await axios.delete('/rapi/locations/' + location.id)
        displaySuccess(result.data.message)
        if (parent) {
          parent.children = parent.children.filter((item) => item !== location)
          setLocations([...locations])
        } else {
          setLocations(locations.filter((item) => item !== location))
        }
      } catch (error) {
        displayError(error)
      }
    }
  }

  const updateLocation = (parent, location, props) => {
    const newLocations = [...locations]
    if (parent) {
      parent.children.forEach((item) => {
        if (item === location) {
          _.assign(item, props)
        }
      })
    } else {
      locations.forEach((item) => {
        if (item === location) {
          _.assign(item, props)
        }
      })
    }
    setLocations(newLocations)
  }

  const updateLocationName = async (parent, location, name) => {
    name = name.trim()
    if (name.length > 0) {
      if (location.temporary) {
        // Create a new location
        try {
          const result = await axios.post('/rapi/locations', {
            parentId: parent ? parent.id : null,
            name: name,
            position: location.position
          })
          displaySuccess(result.data.message)
          updateLocation(parent, location, { name: name, id: result.data.location.id, temporary: false })
        } catch (error) {
          displayError(error)

          const newLocations = removeLocationIdRecursive([...locations], location.id)
          setLocations(newLocations)
        }
      } else {
        // Update existing location with new name
        try {
          const result = await axios.put('/rapi/locations/' + location.id, {
            name: name,
            position: location.position
          })
          displaySuccess(result.data.message)
          updateLocation(parent, location, { name: name })
        } catch (error) {
          displayError(error)
        }
      }
    }
  }

  return (
    <>
      <InventoryLockMessage/>
      <Modal
        modalController={deleteLocationModalController}
        ContentComponent={modalContentConfirm}
        contentTextId="locations.confirm_delete_text"
        confirmTextId="common.delete"
        onConfirm={removeLocation}
      />
      {locationsLoading && <StyledSpinner/>}
      {!locationsLoading && (
        <LocationsMap
          locations={locations}
          addLocation={addLocation}
          removeLocation={checkRemoveLocation}
          updateLocationName={updateLocationName}
          history={history}
        />
      )}
    </>
  )
}

export default withRouter(LocationsPage)
