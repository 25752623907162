import axios from 'axios'
import { HasCapability } from 'Components/capabilities'
import { Button } from 'Components/readonly'
import { AuthContext } from 'Context'
import React, { useContext, useRef } from 'react'
import { useSpring } from 'react-spring'
import styled from 'styled-components'
import svgPanZoom from 'svg-pan-zoom'
import Location from './location'
import LocationLine from './locationLine'
import { layout, leafCount, touchEventHandler } from './locationsSupport'
import { ReactComponent as PlusCircleIcon } from './plus-circle.svg'
import SvgButton from './svgButton'
import SvgTextWrapped from './svgTextWrapped'

const Wrapper = styled.div`
  && {
    padding: 0;
  }

  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  > svg {
    flex: 1 1 1%;
  }

  > ${Button} {
    position: absolute;
    padding: 10px;
    right: 15px;
    bottom: 0;
  }

  circle,
  line {
    stroke-width: 4;
  }

  circle,
  rect {
    fill: #1e2034;
  }

  text {
    fill: #c9c9c9;
  }

  line {
    stroke: #595865;
  }
`

const LocationsMap = ({ locations, addLocation, removeLocation, updateLocationName, history }) => {
  const { user, organisation } = useContext(AuthContext)
  const focusLocation = useRef()

  const [panMomentum, setPanMomentum, stopPanMomentum] = useSpring(() => ({ x: 0, y: 0 }))
  const [locationsPanZoom, setLocationsPanZoom] = React.useState(null)
  const [orgNameHeight, setOrgNameHeight] = React.useState(40)
  const [orgNameWidth,] = React.useState(190)

  const root = React.useMemo(() => {
    const newRoot = {
      // This defines the starting area of the organisation root
      minX: 0,
      minY: 0,
      maxX: 1000,
      maxY: 1000,
      left: {
        x: 400,
        y: 500,
        side: -1,
        children: [],
        leafCount: 0
      },
      right: {
        x: 600,
        y: 500,
        side: 1,
        children: [],
        leafCount: 0
      }
    }

    locations.forEach((l) => leafCount(l))

    // Split locations based on existing position property
    const locationsWithPosition = []
    const locationsWithoutPosition = []
    locations.forEach((l) => {
      if (l.position) {
        locationsWithPosition.push(l)
      } else {
        locationsWithoutPosition.push(l)
      }
    })

    // Add locations with stored position first
    locationsWithPosition.forEach((l) => {
      if (l.position === 'right') {
        newRoot.right.children.push(l)
        newRoot.right.leafCount += l.leafCount + 1
      } else if (l.position === 'left') {
        newRoot.left.children.push(l)
        newRoot.left.leafCount += l.leafCount + 1
      } else {
        locationsWithoutPosition.push(l)
      }
    })

    // Split remaining into left and right side
    // Balance node count left / right
    locationsWithoutPosition.sort((a, b) => b.leafCount - a.leafCount)
    locationsWithoutPosition.forEach((l) => {
      if (newRoot.left.leafCount > newRoot.right.leafCount) {
        l.position = 'right'
        newRoot.right.children.push(l)
        newRoot.right.leafCount += l.leafCount + 1
      } else {
        l.position = 'left'
        newRoot.left.children.push(l)
        newRoot.left.leafCount += l.leafCount + 1
      }
    })

    const saveLocationPositions = locationsWithoutPosition.filter((l) => !l.temporary)
    if (saveLocationPositions.length > 0) {
      const saveData = {
        locations: saveLocationPositions.map((l) => ({
          id: l.id,
          position: l.position
        }))
      }
      try {
        axios.put('/rapi/locations/positions', saveData)
      } catch (e) {
        console.error(e)
      }
    }

    leafCount(newRoot.left)
    leafCount(newRoot.right)

    newRoot.left.children = newRoot.left.children.map((c, index) => layout(newRoot, c, index, newRoot.left))
    newRoot.right.children = newRoot.right.children.map((c, index) => layout(newRoot, c, index, newRoot.right))

    if (focusLocation.current && locationsPanZoom) {
      locationsPanZoom.resize()
      let pan = locationsPanZoom.getPan()
      let panSize = locationsPanZoom.getSizes()
      let panCoord

      panCoord = {
        x: (-focusLocation.current.x * panSize.realZoom - pan.x + panSize.width / 2) / 10,
        y: (-focusLocation.current.y * panSize.realZoom - pan.y + panSize.height / 2) / 10
      }

      if (panCoord) {
        setPanMomentum({
          from: panCoord,
          to: { x: 0, y: 0 },
          immediate: false,
          reset: true,
          onFrame: (v) => {
            locationsPanZoom.panBy(v)
          }
        })
      }

      focusLocation.current.focus = true
      focusLocation.current = null
    }

    return newRoot
  }, [locations, locationsPanZoom, setPanMomentum])

  const preventDefault = (e) => {
    e.preventDefault()
  }

  const ref = React.useRef()
  React.useEffect(() => {
    if (ref.current && !locationsPanZoom) {
      const panZoom = svgPanZoom(ref.current, {
        preventMouseEventsDefault: false,
        customEventsHandler: touchEventHandler(panMomentum, setPanMomentum, stopPanMomentum)
      })
      setLocationsPanZoom(panZoom)
    }

    if (ref.current) {
      ref.current.addEventListener('selectstart', preventDefault)
    }

    const cleanup = () => {
      if (locationsPanZoom) {
        locationsPanZoom.destroy()
        setLocationsPanZoom(null)
        ref.current.removeEventListener('selectstart', preventDefault)
      }
    }

    return cleanup
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, locationsPanZoom])

  const minX = root.minX - 20
  const minY = root.minY - 20
  const maxX = root.maxX + 40
  const maxY = root.maxY + 20

  const fit = () => {
    if (locationsPanZoom) {
      locationsPanZoom.resize()
      locationsPanZoom.updateBBox()
      locationsPanZoom.fit()
      locationsPanZoom.center()
    }
  }

  const addLocationAndFocus = (location) => {
    focusLocation.current = addLocation(location)
  }

  return (
    <Wrapper>
      <Button icon="pi pi-window-maximize" onClick={fit}/>
      <svg ref={ref} xmlns="http://www.w3.org/2000/svg">
        {/* This g is used by svg-pan-zoom to add a transform */}
        <g className="svg-pan-zoom_viewport">
          <rect width={maxX - minX} height={maxY - minY} x={minX} y={minY} opacity="0"/>
          {root.left.children.map((l, index) => (
            <LocationLine key={l.reactKey} index={index} location={l}/>
          ))}
          {root.right.children.map((l, index) => (
            <LocationLine key={l.reactKey} index={index} location={l}/>
          ))}
          {root.left.children.map((l, index) => (
            <Location
              key={l.reactKey}
              index={index}
              location={l}
              addLocation={addLocationAndFocus}
              removeLocation={removeLocation}
              updateLocationName={updateLocationName}
              history={history}
              panZoom={locationsPanZoom}
            />
          ))}
          {root.right.children.map((l, index) => (
            <Location
              key={l.reactKey}
              index={index}
              location={l}
              addLocation={addLocationAndFocus}
              removeLocation={removeLocation}
              updateLocationName={updateLocationName}
              history={history}
              panZoom={locationsPanZoom}
            />
          ))}
          <g transform="translate(500,500)">
            <rect
              x={-(orgNameWidth + (organisation.name.length > 40 ? organisation.name.length * 1.2 : 0)) / 2}
              y={-orgNameHeight / 2}
              rx="20"
              ry="20"
              width={orgNameWidth + (organisation.name.length > 40 ? organisation.name.length * 1.2 : 0) + 'px'}
              height={orgNameHeight + 'px'}
            />
            <SvgTextWrapped
              text={organisation.name}
              x="0"
              y={-orgNameHeight / 2 + 25}
              textAnchor="middle"
              width={orgNameWidth - 10 + (organisation.name.length > 40 ? organisation.name.length * 1.2 : 0)}
              updateTextHeight={(height) => setOrgNameHeight(height + 20)}
            />

            {user.limitLocations.length === 0 && (
              <HasCapability capability="CHEMICAL_MANAGE_LOCATIONS">
                <SvgButton
                  x={(orgNameWidth + (organisation.name.length > 40 ? organisation.name.length * 1.2 : 0)) / 2}
                  y={-orgNameHeight / 2 - 20}
                  onClick={() => addLocationAndFocus()}>
                  <PlusCircleIcon width="24" height="24"/>
                </SvgButton>
              </HasCapability>
            )}
          </g>
        </g>
      </svg>
    </Wrapper>
  )
}

export default LocationsMap
